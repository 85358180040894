"use client";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { signOut } from "next-auth/react";
import { ComponentProps } from "react";
import { CiLogout } from "react-icons/ci";

type UserDropdownProps = ComponentProps<typeof DropdownMenuContent>;

export const UserDropdown = ({
  children,
  className,
  ...props
}: UserDropdownProps) => {
  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className={cn("w-[200px]", className)} {...props}>
        <DropdownMenuLabel>Account</DropdownMenuLabel>

        <DropdownMenuItem onClick={() => signOut()}>
          <CiLogout className="mr-2" />
          <span>Sign Out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
