import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/Users/gionatha/Projects/code-with-gionatha/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/Users/gionatha/Projects/code-with-gionatha/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/gionatha/Projects/code-with-gionatha/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/gionatha/Projects/code-with-gionatha/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SignInWithGithub"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/AuthButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentBanner"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/CookieConsentBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginButton"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogTrigger","DialogContent","DialogHeader","DialogTitle","DialogDescription"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserDropdown"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/UserDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationScroll"] */ "/Users/gionatha/Projects/code-with-gionatha/src/components/utils/NavigationScroll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/gionatha/Projects/code-with-gionatha/src/context/OriginContext.tsx");
