"use client";

import { Button } from "@/components/ui/button";
import { usePlausibleEvent } from "@/hooks/usePlausibleEvent";
import { MouseEventHandler } from "react";
import { FiLogIn } from "react-icons/fi";

type LoginButtonProps = React.ComponentProps<typeof Button>;

export const LoginButton = ({ onClick, ...props }: LoginButtonProps) => {
  const plausible = usePlausibleEvent();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    plausible("header-login");

    onClick?.(e);
  };

  return (
    <Button onClick={handleClick} variant={"outline"} className="w-16 md:w-32">
      <span className="hidden md:block">Login</span>

      <FiLogIn className="w-4 h-4 md:ml-2" />
    </Button>
  );
};
