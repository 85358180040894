"use client";

import { Button } from "@/components/ui/button";
import { usePlausibleEvent } from "@/hooks/usePlausibleEvent";
import { BuiltInProviderType } from "next-auth/providers";
import { signIn, signOut } from "next-auth/react";
import { CiLogout } from "react-icons/ci";
import { FaGithub } from "react-icons/fa";

type AuthProvider = Extract<BuiltInProviderType, "github">;

export function SignInWithGithub({
  provider,
  ...props
}: { provider?: AuthProvider } & React.ComponentPropsWithRef<typeof Button>) {
  const plausible = usePlausibleEvent();

  const handleLogin = () => {
    plausible("sign-in-with-github");

    signIn("github");
  };

  return (
    <Button onClick={handleLogin} className="rounded-md" {...props}>
      <p>Sign in with</p>
      <FaGithub className="ml-2" size={24} />
    </Button>
  );
}

export function SignOut(props: React.ComponentPropsWithRef<typeof Button>) {
  return (
    <Button onClick={() => signOut()} variant="outline" {...props}>
      <span className="hidden md:block">Sign Out</span>
      <CiLogout className="w-4 h-4 md:ml-2" />
    </Button>
  );
}
