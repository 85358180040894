"use client";

import { Button } from "@/components/ui/button";
import { privacyPolicyUrl } from "@/constants/links";
import { cn } from "@/lib/utils";
import NextLink from "next/link";
import { useEffect, useState } from "react";

export const CookieConsentBanner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem("cookie-consent");

    setShow(!hasConsent);
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookie-consent", "true");

    setShow(false);
  };

  return (
    <div
      className={cn(
        "fixed bottom-0 md:bottom-3 left-0 md:left-auto transition-transform duration-300 right-0 md:right-2.5 z-100 bg-black border border-zinc-300 rounded-lg py-3 px-6 text-center text-sm text-gray-50 shadow-lg",
        show ? "translate-y-0" : "translate-y-[160px]"
      )}
    >
      <div className="flex flex-col items-center md:items-end justify-between">
        <p className="text-sm">
          🍪 This site uses cookies to guarantee you the best experience.
        </p>

        <div className="flex gap-2 items-baseline mt-4">
          <NextLink href={privacyPolicyUrl} target="_blank">
            <Button className="text-xs px-4" variant="outline">
              Learn More
            </Button>
          </NextLink>

          <Button
            onClick={handleAccept}
            className="text-xs px-4 py-0"
            variant="primary"
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};
